import api from '../api'
import { ElMessage } from 'element-plus'
import router from '../../routes'
import axios from 'axios'

export default {
  namespaced: true, state: {
    listing: [],
    last_page: 0,
    similar_listings: {},
    seller_information: {},
    videoUrl: {},
    summary: {
      'total_listings': 0
    },
    listings: [],
    loading: false,
    errors: {
      data: {
        errors: {
          property_id: [],
          name: [],
          property_typology_id: [],
          county_id: [],
          local_area: [],
          baths: [],
          phone: [],
          date: []
        }
      }
    },
    has_errors: false
  }, getters: {
    loading: state => state.loading,
    last_page: state => state.last_page,
    seller_information: state => state.seller_information,
    listing: state => state.listing,
    listings: state => state.listings,
    summary: state => state.summary,
    errors: state => state.errors,
    has_errors: state => state.has_errors,
    similar_listings: state => state.similar_listings,
    video_url: state => state.videoUrl
  }, mutations: {
    setSimilarListings(state, data) {
      state.similar_listings = data
    },
    setSummary(state, val) {
      state.summary = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setLastPage(state, val) {
      state.last_page = val
    },
    setSeller(state, val) {
      state.seller_information = val
    },

    setListingsScroll(state, data) {
      let listings = state.listings

      listings.push(...data)

      state.listings = listings
    },
    setListings(state, data) {

      state.listings = data

    },

    setListingsSearch(state, data) {
      state.listings = data
    },

    setVideoUrl(state, data) {
      state.videoUrl = data
    },

    setListing(state, data) {
      state.listing = data
    }, setErrors(state, errors) {
      state.errors = errors
    }, hasErrors(state, val) {
      state.has_errors = val
      if (!val) {
        state.errors = {
          data: {
            errors: {
              property_id: [], name: []
            }
          }
        }
      }
    }
  }, actions: {
    async getSimilarListings({ commit }, filters) {
      try {
        commit('activateLoading', 'property/list', { root: true })
        const response = await api.getSimilarListings(filters)
        commit('setSimilarListings', response)
        commit('deactivateLoading', 'property/list', { root: true })
      } catch (e) {
        // console.log(e)
        // ElMessage.error(e.response.data.message);
      }
    },
    async getListing({ commit }, listing_id) {
      try {
        const response = await api.getListing(listing_id)
        commit('setListing', response.data)
      } catch (e) {
        ElMessage.error('')
      }
    },

    async uploadListingVideo({ commit }, data) {

      const formData = new FormData()
      formData.append('file', data.file)  // Append the video file to the form data
      try {
        axios.defaults.headers.common.Authorization = null
        // Make the Axios POST request
        const response = await axios.put(data.url, data.file, {
          headers: data.headers
        })
      } catch (error) {
        this.$message.error('Upload failed, please try again')
      }
    },
    async generatePresignedUrl({ commit }, filename) {
      try {
        // Only send the filename, not the file itself
        const response = await api.generatePresignedUrl(filename)
        commit('setVideoUrl', response?.url)
      } catch (e) {
        commit('setLoading', false)
        ElMessage.error({
          'message': e?.response?.data?.message || 'Something went wrong.'
        })
      }
    },
    async getListings({ commit }, filters) {
      try {
        if (filters.reset) {
          commit('setListings', [])
        }
        commit('setLoading', true)
        commit('activateLoading', 'listing/list', { root: true })
        let user = localStorage.getItem('user')
        const response = await api.getListings({
          ...filters,
          user_id: JSON.parse(user)?.id
        })
        commit('setLastPage', response?.data?.last_page)
        if (filters.search) {
          commit('setListingsSearch', response?.data?.data)
        } else {
          commit('setListings', response?.data?.data)
        }
        commit('deactivateLoading', 'listing/list', { root: true })
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        ElMessage.error({
          'message': e?.response?.data?.message || 'Something went wrong.'
        })
      }
    },

    async getListingsScroll({ commit }, filters) {
      try {
        commit('setLoading', true)
        commit('activateLoading', 'listing/list', { root: true })
        const response = await api.getListings(filters)
        commit('setLastPage', response?.data?.last_page)
        commit('setListingsScroll', response?.data?.data)
        commit('deactivateLoading', 'listing/list', { root: true })
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        ElMessage.error({
          'message': e.response.data.message
        })
      }
    },

    async getUserListings({ commit }, filters) {
      try {
        commit('activateLoading', 'listing/list', { root: true })
        const response = await api.getUserListings(filters)
        commit('setListings', response)
        commit('deactivateLoading', 'listing/list', { root: true })
      } catch (e) {
        ElMessage.error({
          'message': e.response.data.message
        })
      }
    },
    async getUserListingSummary({ commit }, filters) {
      try {
        commit('activateLoading', 'listing/list', { root: true })
        const response = await api.getUserListingSummary(filters)
        commit('setSummary', response)
        commit('deactivateLoading', 'listing/list', { root: true })
      } catch (e) {
        ElMessage.error({
          'message': e.response.data.message
        })
      }
    },

    async createListings({ dispatch, commit }, data) {
      try {
        commit('hasErrors', false)
        commit('setLoading', true)
        const response = await api.createListing(data)
        ElMessage.success({
          message: 'Added listing successfully!',
          offset: 100
        })
        commit('setLoading', false)
        if (response?.boost === true) {
          await router.replace('/boost-listing/' + response?.data?.id + '/' + response?.package_id)
        } else {
          if (response?.is_business === true) {
            await router.replace('/business/catalogue')
          } else {
            await router.replace('/my-listings')
          }
        }
      } catch (e) {
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: e.response.data.message,
          offset: 100
        })
        commit('setLoading', false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    async removeImage({ dispatch, commit }, data) {
      try {
        commit('hasErrors', false)
        commit('setLoading', true)
        const response = await api.removeImage(data)
        ElMessage.success({
          message: 'Image Removed successfully!',
          offset: 100
        })
        commit('setLoading', false)
      } catch (e) {
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: e.response.data.message,
          offset: 100
        })
        commit('setLoading', false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    async addComment({ dispatch, commit }, data) {
      try {
        commit('hasErrors', false)
        commit('setLoading', true)
        await api.addComment(data)
        ElMessage.success({
          message: 'Added comment successfully!',
          offset: 100
        })
        commit('setLoading', false)
      } catch (e) {
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: e.response.data.message,
          offset: 100
        })
        commit('setLoading', false)
      }
    },

    async editListing({ dispatch, commit }, data) {
      try {
        commit('setLoading', true)
        const response = await api.editListing(data)
        ElMessage.success({
          message: 'Information updated successfully.',
          offset: 100
        })
        commit('setLoading', false)
        if (response?.is_business === true) {
          await router.replace('/business/catalogue')
        } else {
          await router.replace('/my-listings')
        }

      } catch (e) {
        // console.log(e)
        commit('setLoading', false)
        commit('hasErrors', true)
        commit('setErrors', e.response)
        ElMessage.error({
          message: e.response.data.message,
          offset: 100
        })
      }
    },

    async getSeller({ commit }, filters) {
      commit('setLoading', true)
      try {
        const response = await api.getSeller(filters)
        commit('setSeller', response)
        commit('setLoading', false)
      } catch (e) {
        ElMessage.error(e.response.data.message)
        commit('setLoading', false)
      }
    },

    async updateListingViews({ commit }, id) {
      try {
        commit('activateLoading', 'property/list', { root: true })
        const response = await api.updateListingViews(id)
        commit('deactivateLoading', 'property/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    },
    async setNotificationUserHistory({ commit }, data) {
      try {
        commit('activateLoading', 'property/list', { root: true })
        const response = await api.setNotificationUserHistory(data)
        commit('deactivateLoading', 'property/list', { root: true })
      } catch (e) {
        ElMessage.error(e.response.data.message)
      }
    }
  }
}
